import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Waypoint } from 'react-waypoint';
import classNames from "classnames";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import AOS from 'aos';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import scrollTo from 'gatsby-plugin-smoothscroll';
import '@splidejs/splide/dist/css/splide.min.css';
import { useWindowWidth } from "../hooks/useWindowWidth";
import {useIsSSR} from '../hooks/useIsSSR';
import 'hamburgers/dist/hamburgers.min.css';

import "../assets/css/header.scss";
import "../assets/css/footer.scss";
import "../assets/css/home.scss";
import "aos/dist/aos.css";

import HeroMovil from "../assets/images/landing/Hero_Movil.png"

import DashboardVideo from "../assets/video/Explicacion dashboard.mp4";
import HeroYes from "../assets/video/Hero_Yes.mp4";
import HeroYesMovil from "../assets/video/Hero_Yes_Movil.mp4";

import Logo from "../assets/images/logo_yes.svg";
import AppStore from "../assets/images/landing/App_Sotre_(transparentes).svg";
import GooglePlay from "../assets/images/landing/Google_Play_(transparentes).svg";
import Frase from "../assets/images/landing/Frase.svg";
import IconoMarketing from "../assets/images/landing/Icono_marketing.svg";
import IconoIngresos from "../assets/images/landing/Icono_ingresos.svg";
import IconoClases from"../assets/images/landing/Icono_clases.svg";
import IconoAsociate from "../assets/images/landing/Icono_asociate.svg"
import Beneficios from "../assets/images/landing/Beneficios.svg";
import IconoSearch from "../assets/images/landing/Search.svg";

import IconDance from "../assets/images/landing/Icono_dance.svg";
import IconSprint from "../assets/images/landing/Icono_sprint.svg";
import IconRun from "../assets/images/landing/Icono_run.svg";
import IconJump from "../assets/images/landing/Icono_jump.svg";
import IconGym from "../assets/images/landing/Icono_gym.svg";
import IconBox from "../assets/images/landing/Icono_box.svg";
import IconBar from "../assets/images/landing/Icono_bar.svg";
import IconKick from "../assets/images/landing/Icono_kick.svg";
import IconBag from "../assets/images/landing/Icono_bag.svg";
import IconBall from "../assets/images/landing/Icono_ball.svg";
import IconWeight from "../assets/images/landing/Icono_weight.svg";
import IconCrouch from "../assets/images/landing/Icono_crouch.svg";
import IconBike from "../assets/images/landing/Icono_bike.svg";
import IconShoe from "../assets/images/landing/Icono_shoe.svg";

import Box from "../assets/images/landing/Box.svg";
import Barre from "../assets/images/landing/Barre.svg";
import Crossfit from "../assets/images/landing/Crossfit.svg";
import Cycling from "../assets/images/landing/Cycling.svg";
import Yoga from "../assets/images/landing/Yoga.svg";

const ContactSchema = Yup.object().shape({
  nombre: Yup.string().required('Requerido'),
  email: Yup.string().email('Email inválido').required('Requerido'),
});

const FooterContactSchema = Yup.object().shape({
  nombre: Yup.string().required('Requerido'),
  studio: Yup.string().required('Requerido'),
  mensaje: Yup.string().required('Requerido'),
  nombre: Yup.string().required('Requerido'),
  email: Yup.string().email('Email inválido').required('Requerido'),
});

const HomeSections = () => {
  const {isSsr} = useIsSSR();
  const [windowWidth] = useWindowWidth();
  const [stickyNav, setstickyNav] = useState(false);
  const [activeHamburger, setActiveHamburger] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [footerFormSubmitted, setFooterFormSubmitted] = useState(false);
  
  useEffect(() => {
    AOS.init();
  }, [])

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&');
  };

  const handleSubmit = (values) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(values),
    })
      .then(() => {
        setFormSubmitted(true);
      })
      .catch((error) => alert(error));
  };

  const handleFooterSubmit = (values) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(values),
    })
      .then(() => {
        setFooterFormSubmitted(true);
      })
      .catch((error) => alert(error));
  };

  return (
    <>
      <button
        onClick={() => {
          setActiveHamburger(!activeHamburger);
        }}
        className={classNames(
          'hamburger hamburger--collapse',
          {'is-active': activeHamburger},
          {'sticky': stickyNav},
        )}
        type="button"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <div className={classNames(
          'mobile-menu',
          {'active': activeHamburger},
        )}>
        <nav className="main-menu">
          <a className="menu-link" onClick={() => {
            scrollTo('#conocenos');
            setActiveHamburger(false);
          }}>
            Conócenos
          </a>
          <a className="menu-link" onClick={() => {
            scrollTo('#nosotros');
            setActiveHamburger(false);
          }}>
            Nosotros
          </a>
          <a className="menu-link" onClick={() => {
            scrollTo('#estudios');
            setActiveHamburger(false);
          }}>
            Estudios
          </a>
          <a className="menu-link" onClick={() => {
            scrollTo('#beneficios');
            setActiveHamburger(false);
          }}>
            Beneficios
          </a>
          <a className="menu-link" onClick={() => {
            scrollTo('#porque');
            setActiveHamburger(false);
          }}>
            ¿Por qué?
          </a>
          <a className="menu-link menu-button" onClick={() => {
            scrollTo('#contactanos');
            setActiveHamburger(false);
          }}>
            Contáctanos
          </a>
        </nav>
      </div>
      <header className={classNames({'active': stickyNav})}>
        <div className="menu-container">
          <div className="logo">
            <svg alt="Yes! Fitness logo" className="header-logo">
              <use xlinkHref={`${Logo}#Capa_2`} />
            </svg>
          </div>

          <nav className="main-menu">
            <a className="menu-link" onClick={() => scrollTo('#conocenos')}>Conócenos</a>
            <a className="menu-link" onClick={() => scrollTo('#nosotros')}>Nosotros</a>
            <a className="menu-link" onClick={() => scrollTo('#estudios')}>Estudios</a>
            <a className="menu-link" onClick={() => scrollTo('#beneficios')}>Beneficios</a>
            <a className="menu-link" onClick={() => scrollTo('#porque')}>¿Por qué?</a>
            <a className="menu-link menu-button" onClick={() => scrollTo('#contactanos')}>Contáctanos</a>
          </nav>
        </div>
      </header>
      <section className="hero">
        {
          windowWidth <= 992 ? (
            <video autoPlay muted loop className="hero-video" poster={HeroMovil}>
              <source src={HeroYesMovil} type="video/mp4" />
            </video>
          ) : (
            <video autoPlay muted loop className="hero-video" poster={HeroMovil}>
              <source src={HeroYes} type="video/mp4" />
            </video>
          )
        }
        <div className="logos">
          <a href="https://apps.apple.com/mx/app/yes-fitness-m%C3%A9xico/id1598815706" target="_blank">
            <img src={AppStore} alt="App Store" width={230}/>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.yesfitness" target="_blank">
            <img src={GooglePlay} alt="Google Play" width={230}/>
          </a>
        </div>
      </section>

      <Waypoint
        onLeave={() => setstickyNav(true)}
        onEnter={() => setstickyNav(false)}
        topOffset='40%'
      />
      <section className="know-us" id="conocenos" data-aos="fade-up">
        <img src={Frase}  alt="Yes! Fitness, el acceso a los mejores estudio fitness de la ciudad" />
        <div className="sliders">
          <Splide
            options={{
              type: 'loop',
              perPage: 4,
              perMove: 1,
              autoplay: true,
              arrows: false,
              pagination: false,
              interval: 3000,
            }}
          >
            <SplideSlide>
              <img src={IconBike} alt="bike" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconShoe} alt="shoe" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconDance} alt="dance" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconSprint} alt="sprint" height={80}/>
            </SplideSlide>
          </Splide>

          <Splide
            options={{
              type: 'loop',
              perPage: 4,
              perMove: 1,
              autoplay: true,
              arrows: false,
              pagination: false,
              interval: 3000,
              direction: 'rtl',
            }}
          >
            <SplideSlide>
              <img src={IconRun} alt="run" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconJump} alt="jump" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconWeight} alt="weight" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconGym} alt="gym" height={80}/>
            </SplideSlide>
          </Splide>

          <Splide
            options={{
              type: 'loop',
              perPage: 4,
              perMove: 1,
              autoplay: true,
              arrows: false,
              pagination: false,
              interval: 3000,
            }}
          >
            <SplideSlide>
              <img src={IconBox} alt="box" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconBar} alt="bar" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconKick} alt="kick" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconBag} alt="bag" height={80}/>
            </SplideSlide>
          </Splide>

          <Splide
            options={{
              type: 'loop',
              perPage: 4,
              perMove: 1,
              autoplay: true,
              arrows: false,
              pagination: false,
              interval: 3000,
              direction: 'rtl',
            }}
          >
            <SplideSlide>
              <img src={IconBall} alt="ball" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconShoe} alt="shoe" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconDance} alt="dance" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconSprint} alt="sprint" height={80}/>
            </SplideSlide>
          </Splide>

          <Splide
            options={{
              type: 'loop',
              perPage: 4,
              perMove: 1,
              autoplay: true,
              arrows: false,
              pagination: false,
              interval: 3000,
            }}
          >
            <SplideSlide>
              <img src={IconRun} alt="run" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconJump} alt="jump" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconWeight} alt="weight" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconBar} alt="bar" height={80}/>
            </SplideSlide>
          </Splide>

          <Splide
            options={{
              type: 'loop',
              perPage: 4,
              perMove: 1,
              autoplay: true,
              arrows: false,
              pagination: false,
              interval: 3000,
              direction: 'rtl',
            }}
          >
            <SplideSlide>
              <img src={IconBox} alt="box" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconBar} alt="bar" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconKick} alt="kick" height={80}/>
            </SplideSlide>
            <SplideSlide>
              <img src={IconBag} alt="bag" height={80}/>
            </SplideSlide>
          </Splide>
        </div>
      </section>

      <section className="about-us" id="nosotros">
        <div className="small-container">
          <div className="nosotros-grid">
            <StaticImage src="../assets/images/landing/iphone.png" placeholder="dominantColor" alt="iPhone"/>
            <div className="nosotros-description" data-aos="fade-up">
              <h2>¿Qué es <br/>Yes Fitness?</h2>
              <p>Yes fitness es una aplicación que vincula a los mejores estudios fitness de tu ciudad en una misma oferta.</p>
              <p>Nosotros nos asociamos con los estudios y cubrimos la disponibilidad que lleguen a tener en sus clases.</p>
            </div>

            <h3 data-aos="fade-up">Lo que ofrecemos</h3>
            <p className="medium" data-aos="fade-up">Negociamos un precio por clase con los estudios y ofrecemos a los usuarios diferentes paquetes para que ellos puedan reservar su clase preferida.</p>

          </div>
        </div>
      </section>

      <section className="estudios" id="estudios">
        <div className="small-container" data-aos="fade-up">
          <h2>¿Tienes un estudio?</h2>
          <p>Administra en nuestra plataforma tus diferentes reservaciones y analiza de forma sencilla la información de los usuarios.</p>
          <video controls controlsList="nodownload">
            <source src={DashboardVideo} type="video/mp4" />
          </video>
        </div>
      </section>

      <section className="studio-contact-form" id="contactanos" data-aos="fade-up">
        <h2>Conoce Más</h2>
        <h3>Contactanos</h3>

        <div className="tiny-container">
          <Formik
            initialValues={{
              nombre: '',
              email: '',
              whatsapp: '',
              'form-name': 'contact',
            }}
            validationSchema={ContactSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form data-netlify="true" name="contact">
                <div className="input-wrapper">
                  <Field type="hidden" name="form-name" />
                  <label>Nombre</label>
                  <Field
                    id="nombre"
                    name="nombre"
                    type="text"
                    error={touched.nombre && errors.nombre}
                  />
                  {touched.nombre && errors.nombre && (
                    <div className="feedback">
                      {errors.nombre}
                    </div>
                  )}
                </div>
                <div className="input-wrapper">
                  <label>Email</label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    error={touched.email && errors.email}
                  />
                  {touched.email && errors.email && (
                    <div className="feedback">
                      {errors.email}
                    </div>
                  )}
                </div>
                <div className="input-wrapper">
                  <label>WhatsApp <span>(opcional)</span></label>
                  <Field
                    id="whatsapp"
                    name="whatsapp"
                    type="text"
                    error={touched.whatsapp && errors.whatsapp}
                  />
                </div>
                <div className="input-wrapper button-wrapper">
                  <button type="submit">Enviar</button>
                </div>

                {formSubmitted && (
                  <div className="feedback">
                    ¡Gracias por contactarnos!
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </section>

      <section className="benefits" id="beneficios">
        <div className="medium-container">
          <div className="benefits-container">
            <h2 className="benefits-title-mobile">Beneficios</h2>

            <div className="benefits-list">

              <div className="benefit" data-aos="fade-up">
                <img src={IconoMarketing} alt="marketing"/>
                <div className="benefit-info">
                  <h4>MARKETING DIGITAL</h4>
                  <p>Genera mayor difusión. Y disfruta de la promoción de tu lugar a través de nuestra plataforma y redes sociales.</p>
                </div>
              </div>
              <div className="benefit" data-aos="fade-up">
              <img src={IconoIngresos} alt="ingresos"/>
                <div className="benefit-info">
                  <h4>INGRESOS ADICIONALES</h4>
                  <p>Genera un ingreso extra y gana más.</p>
                </div>
              </div>
              <div className="benefit" data-aos="fade-up">
              <img src={IconoClases} alt="clases"/>
                <div className="benefit-info">
                  <h4>CLASES LLENAS</h4>
                  <p>Llena esos lugares vacíos que llegues a tener, y ve cómo se enriquece la experiencia de tu clase.</p>
                </div>
              </div>
              <div className="benefit" data-aos="fade-up">
              <img src={IconoAsociate} alt="asociate"/>
                <div className="benefit-info">
                  <h4>ASOCIATE A YES!</h4>
                  <p>Aprovecha de nuestra tecnología fácil de usar para checar reportes y estadísticas de usuarios.</p>
                </div>
              </div>

            </div>

            <img src={Beneficios} alt="beneficios" className="benefits" data-aos="fade-up"/>
          </div>
        </div>
      </section>

      <section className="why" id="porque" data-aos="fade-up">
        <div className="sphere">
          <img src={Box} alt="Box" height={100} className="box" data-aos="zoom-in" data-aos-delay="200"/>
          <img src={Barre} alt="Barre" height={100} className="barre" data-aos="zoom-in" data-aos-delay="250"/>
          <img src={Crossfit} alt="Crossfit" height={100} className="crossfit" data-aos="zoom-in" data-aos-delay="300"/>
          <img src={Cycling} alt="Cycling" height={100} className="cycling" data-aos="zoom-in" data-aos-delay="350"/>
          <img src={Yoga} alt="Yoga" height={100} className="yoga" data-aos="zoom-in" data-aos-delay="400"/>
        </div>

        <div className="medium-container">
          <div className="motivation">
            <div className="fake-button">
              <img src={IconoSearch} width={36} alt="search"/>
              <span>¿Qué te motiva hoy?</span>
            </div>
          </div>
        </div>
      </section>

      <footer data-aos="fade-up">
        <div className="medium-container">
          <div className="footer-grid">
            <div className="form-wrapper">
              <h2>Contáctanos</h2>
              <p>¡Únete a Yes fitness! Inicia tu registro y comienza a crecer tu negocio con nosotros.</p>

              <Formik
                initialValues={{
                  nombre: '',
                  email: '',
                  studio: '',
                  mensaje: '',
                  whatsapp: '',
                  'form-name': 'footer-contact',
                }}
                validationSchema={FooterContactSchema}
                onSubmit={handleFooterSubmit}
              >
                {({ errors, touched }) => (
                  <Form data-netlify="true" name="footer-contact" className="footer-form">
                    <div className="input-wrapper">
                      <label>Nombre</label>
                      <Field
                        id="nombre"
                        name="nombre"
                        type="text"
                        error={touched.nombre && errors.nombre}
                      />
                      {touched.nombre && errors.nombre && (
                        <div className="feedback">
                          {errors.nombre}
                        </div>
                      )}
                    </div>
                    <div className="input-wrapper">
                      <label>Nombre de Estudio</label>
                      <Field
                        id="studio"
                        name="studio"
                        type="text"
                        error={touched.studio && errors.studio}
                      />
                      {touched.studio && errors.studio && (
                        <div className="feedback">
                          {errors.studio}
                        </div>
                      )}
                    </div>
                    <div className="input-wrapper">
                      <label>Correo</label>
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        error={touched.email && errors.email}
                      />
                      {touched.email && errors.email && (
                        <div className="feedback">
                          {errors.email}
                        </div>
                      )}
                    </div>
                    <div className="input-wrapper">
                      <label>WhatsApp <span>(opcional)</span></label>
                      <Field
                        id="whatsapp"
                        name="whatsapp"
                        type="text"
                        error={touched.whatsapp && errors.whatsapp}
                      />
                    </div>
                    <div className="input-wrapper textarea">
                      <label>Mensaje</label>
                      <Field
                        id="mensaje"
                        name="mensaje"
                        as="textarea"
                        error={touched.mensaje && errors.mensaje}
                      />
                      {touched.mensaje && errors.mensaje && (
                        <div className="feedback">{errors.mensaje}</div>
                      )}
                    </div>

                    <button type="submit">Enviar</button>

                    {footerFormSubmitted && (
                      <div className="feedback">
                        ¡Gracias por contactarnos!
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>

            <div className="footer-logo-wrapper">
            <img src={Logo} className="footer-logo" alt="Yes! Fitness logo" width={206}/>
              <div className="copyright">© 2022 por Yes! fitness.</div>
            </div>
          </div>

          <div className="foot">
            <a href="https://sites.google.com/yesfitness.mx/avisodeprivacidad/inicio" target="_blank" className="terms">Aviso de Privacidad</a>
            <a href="https://apps.apple.com/mx/app/yes-fitness-m%C3%A9xico/id1598815706" target="_blank">
              <img src={AppStore} alt="App Store" width={198}/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.yesfitness" target="_blank">
              <img src={GooglePlay} alt="Google Play" width={198}/>
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}

export default HomeSections;