import {useState, useEffect} from "react"

export const useWindowWidth = () => {
  let width;
  if (typeof window !== 'undefined') {
    width = window.innerWidth
  }

  const [windowWidth, setWindowWidth] = useState(width);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  return [windowWidth]
}